import React from "react";
import SectionTitle from "../components/SectionTitle";
import Card from "../components/Card";
import { Link } from "react-router-dom";

const MensTrimmer = ({ mensTrimmerProduct }) => {
  return (
    <div className=" container mx-auto mb-5">
      <div className="flex items-center justify-center">
        <SectionTitle title={"MensTrimmer"} />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-1">
        {mensTrimmerProduct?.map((product) => (
          <div key={product.id}>
            <div className=" overflow-hidden">
              <Card product={product} />
            </div>
          </div>
        ))}
      </div>
      {mensTrimmerProduct.length === 10 ? (
        <div className="flex items-center justify-center mt-10">
          <Link
            to="categorypage/60"
            className="bg-[#4FC6DF] text-white hover:bg-green-600 px-10 py-3 rounded-md duration-300"
          >
            view all
          </Link>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MensTrimmer;
