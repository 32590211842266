import { useEffect, useState } from "react";
import Banner from "../components/Banner";
import BannerToow from "../components/BannerToow";
import CategoryCard from "../components/CategoryCard";
import ProductCard from "../components/ProductCard";

import BannerSlider from "./BannerSlider";
import BestSelling from "./BestSelling";
import FeaturedProduct from "./FeaturedProduct";
import SectionTitle from "../components/SectionTitle";
import MensTrimmer from "./MensTrimmer";
import WomensTrimmer from "./WomensTrimmer";
import HairDryes from "./HairDryes";
import HairStraightener from "./HairStraightener";

// import ReviewCardPage from "./ReviewCardPage";

const Home = () => {
  const [visible, setVisible] = useState(30);

  const handleSeeMore = () => {
    setVisible((prevValue) => prevValue + 12);
  };

  const handleless = () => {
    setVisible((prevValue) => prevValue - 6);
  };

  const [mensTrimmerProduct, setMensTrimmerProduct] = useState([]);
  const [womensTrimmerProduct, setWomensTrimmerProduct] = useState([]);
  const [haiDryer, setHaiDryer] = useState([]);
  const [hairStraightener, setHairStraightener] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api-categories/60`
        );
        const data = await res.json();
        setMensTrimmerProduct(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);

  // womens trimmer
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api-categories/61`
        );
        const data = await res.json();
        setWomensTrimmerProduct(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);

  // hairdryer
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api-categories/66`
        );
        const data = await res.json();
        setHaiDryer(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);
  // hairStraightener
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api-categories/67`
        );
        const data = await res.json();
        setHairStraightener(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="container mx-auto py-4 2xl:px-0 xl:px-14">
      <BannerSlider />

      <div>
        <span className="flex items-center justify-center">
          <SectionTitle title={"Categories"} />
        </span>
        <CategoryCard />
      </div>

      {/* <FeaturedProduct
        visible={visible}
        handleSeeMore={handleSeeMore}
        handleless={handleless}
      /> */}
      <MensTrimmer mensTrimmerProduct={mensTrimmerProduct} />
      <WomensTrimmer womensTrimmerProduct={womensTrimmerProduct} />
      <HairDryes haiDryer={haiDryer} />
      <HairStraightener hairStraightener={hairStraightener} />
      <Banner />
    </div>
  );
};

export default Home;
