import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SubCat = ({
  handleMenuHover,
  getMenuIcon,
  getSubcategoriesByCategoryId,
  handleMenuLeave,
  expandedMenuIndex,
  activeMenuIndex,
}) => {
  const { items: category } = useSelector((state) => state.category);
  // const { items: subCat } = useSelector((state) => state.subCategor);

  return (
    <div className="category-thumb category-container rounded p-2 flex flex-col gap-3 h-full  bg-white   md:h-[21rem] 2xl:h-[30rem] overflow-y-scroll">
      {category.map((category, index) => (
        <div
          key={category.id}
          className="mr-4"
          onMouseEnter={() => handleMenuHover(index)}
          onMouseLeave={handleMenuLeave}
        >
          <Link
            to={`subcategory/${category.id}`}
            className="cursor-pointer flex items-center font-semibold text-black/60  "
          >
            <div className="image w-[35px] h-[35px] rounded-full overflow-hidden ">
              <img
                src={`${process.env.REACT_APP_URL}/uploads/category/${category.icon}`}
                alt={category.alt}
                className="w-full h-full object-cover"
              />
            </div>
            <p className="text-sm lg:text-base">{category.name}</p>

            {/* {getMenuIcon(index)} */}
          </Link>
          {(expandedMenuIndex === index || activeMenuIndex === index) && (
            <div className="mt-2 shadow-lg rounded opacity-100 bg-white scale-100 transition-opacity w-[30rem] h-96 duration-300 group-hover:opacity-100 group-hover:scale-100 absolute top-0 md:left-40 lg:left-64 z-[99999] flex flex-col p-5">
              <div className="grid gap-3">
                {getSubcategoriesByCategoryId(category.id).map(
                  (subCat, subIndex) => (
                    <Link
                      // onClick={() => setshowCategoryMenu(false)}
                      key={subIndex}
                      to={`subcategory/${subCat.id}`}
                    >
                      <span>{subCat.name}</span>
                    </Link>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SubCat;
